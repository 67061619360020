import { ApplicationsService } from '@/modules/applications/applications-service';
import Errors from '@/shared/error/errors';

// action types
export const DO_FETCH = 'doFetch';
export const DO_SELECT = 'doSelect';
export const DO_DELETE_APPLICATION = 'doRemove';
export const DO_FETCH_APPLICATION_TYPES = 'fetchApplicationTypesByOrganisation';
export const SET_FILTER = 'doSetFilter';

// mutation types
export const FETCH_SUCCESS = 'fetchSuccess';
export const SET_CURRENT_PAGE = 'setCurrentPage';
export const SET_FILTERS = 'setFilters';
export const SET_FILTERS_PARAMS = 'setFiltersParams';
export const SET_SORT = 'setSort';
export const FETCH_COUNT = 'fetchCount';
export const SET_SELECTED_ROWS = 'setSelectedRows';

const state = {
    rows: [],
    total: 0,
    storeFilters: {},
    currentPage: 1,
    countApplicationsAssigned: 0,
    selectedRows: [],
    storeSort: {sortBy: '', sortDesc: false},
};

const getters = {
    rows: ({ rows }) => rows,
    total: (state) => state.total,
    filters: (state) => state.storeFilters,
    currentPage: (state) => state.currentPage,
    countApplicationsAssigned: (state) => state.countApplicationsAssigned,
    selectedRows: (state) => state.selectedRows,
    sorting: (state) => state.storeSort,
};

const CUSTOM_SORTING = {
    identification_number: 'allocated_identification_number',
    allocated_document_series: 'allocated_document_series_number'
}
const actions = {
    [DO_FETCH](context, payload) {
        return new Promise((resolve, reject) => {
            context.commit('shared/activateLoading', 'applications/get', { root: true });

            const { loadStaff, params, countApplicationsAssigned } = payload;
            const sortParam = params.sort;

            if (params?.sort && (params.sort.includes('identification_number') || params.sort.includes('allocated_document_series'))){
                const sortKey = Object.keys(CUSTOM_SORTING).find((key) => sortParam.includes(key));
                const sortOrder = sortParam.split(sortKey);
                
                params.sort = `${sortOrder[0]}${CUSTOM_SORTING[sortKey]}`; // sorting by identification_number and allocated_document_series needs custom sorting key
            }

            let filters = { params: { ...params } };
            if (loadStaff) {
                //params.internal = false; // do not fetch internal documents.

                if (params && !params?.sort) {
                    filters = { params: { ...params , sort: '-application_date'} };
                }
            } 

            const method = loadStaff ? 'listStaff' : 'list';
            ApplicationsService[method](filters)
            .then((data) => {
                if (countApplicationsAssigned) {
                    context.commit(FETCH_COUNT,  data?.pagination?.total);
                } else {
                    context.commit(FETCH_SUCCESS, {
                        rows: data.data,
                        total: data?.pagination?.total,
                    });
                }
                resolve(data);
            })
            .catch(( error ) => {
                Errors.handle(error);
                reject();
            })
            .finally(() => {
                context.commit('shared/deactivateLoading', 'applications/get', { root: true });
            });

        });
    },
    [DO_SELECT](context, payload) {
        context.commit(SET_SELECTED_ROWS, payload);
    },
    [DO_DELETE_APPLICATION](context, id) {
        context.commit('shared/activateLoading', 'admin/deleteApplication', { root: true });
        return new Promise((resolve, reject) => {
            ApplicationsService.deleteApplication(id)
            .then(() => {
                resolve();
            })
            .catch((error) => {
                Errors.handle(error);
                reject();
            })
            .finally(() => {
                context.commit('shared/deactivateLoading', 'admin/deleteApplication', { root: true });
            })
        });
    },
    [DO_FETCH_APPLICATION_TYPES](context) {
        context.commit('shared/activateLoading', 'applications/applicationTypesByOrg', { root: true });
        return new Promise((resolve, reject) => {
            ApplicationsService.getApplicationTypesByOrganisation()
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'applications/applicationTypesByOrg', { root: true });
                })
        });
    },
    [SET_FILTER](context, { key, value, withResetAll }) {
            context.commit(SET_FILTERS, { key, value, withResetAll })
    }
};

const mutations = {
    [FETCH_SUCCESS](state, payload) {
        state.rows = payload.rows;
        state.total = payload.total;
    },
    [SET_CURRENT_PAGE](state, page) {
        state.currentPage = page;
    },
    [FETCH_COUNT](state, total) {
        state.countApplicationsAssigned = total;
    },
    [SET_FILTERS](state, { key, value, withResetAll }) {
        if (withResetAll) {
            state.storeFilters = {};
        }
        state.storeFilters[key] = value;
    },
    [SET_SORT](state, value) {
        state.storeSort = value;
    },
    [SET_SELECTED_ROWS](state, payload) {
        state.selectedRows = payload;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
